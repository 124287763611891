<style lang="scss" scoped></style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 8.8H0V6.4C0 5.76348 0.252856 5.15303 0.702944 4.70294C1.15303 4.25286 1.76348 4 2.4 4H21.6C22.2365 4 22.847 4.25286 23.2971 4.70294C23.7471 5.15303 24 5.76348 24 6.4V8.8ZM24 12.4V18.4C24 19.0365 23.7471 19.647 23.2971 20.0971C22.847 20.5471 22.2365 20.8 21.6 20.8H2.4C1.76348 20.8 1.15303 20.5471 0.702944 20.0971C0.252856 19.647 0 19.0365 0 18.4V12.4H24ZM4.8 14.8C4.48174 14.8 4.17652 14.9264 3.95147 15.1515C3.72643 15.3765 3.6 15.6817 3.6 16C3.6 16.3183 3.72643 16.6235 3.95147 16.8485C4.17652 17.0736 4.48174 17.2 4.8 17.2H6C6.31826 17.2 6.62348 17.0736 6.84853 16.8485C7.07357 16.6235 7.2 16.3183 7.2 16C7.2 15.6817 7.07357 15.3765 6.84853 15.1515C6.62348 14.9264 6.31826 14.8 6 14.8H4.8ZM10.8 14.8C10.4817 14.8 10.1765 14.9264 9.95147 15.1515C9.72643 15.3765 9.6 15.6817 9.6 16C9.6 16.3183 9.72643 16.6235 9.95147 16.8485C10.1765 17.0736 10.4817 17.2 10.8 17.2H16.8C17.1183 17.2 17.4235 17.0736 17.6485 16.8485C17.8736 16.6235 18 16.3183 18 16C18 15.6817 17.8736 15.3765 17.6485 15.1515C17.4235 14.9264 17.1183 14.8 16.8 14.8H10.8Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>
