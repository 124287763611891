<style lang="scss" scoped></style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 7.5V6C18.5 2.69156 15.8084 0 12.5 0C9.19156 0 6.5 2.69156 6.5 6V7.5H2V20.25C2 22.3211 3.67892 24 5.75 24H19.25C21.3211 24 23 22.3211 23 20.25V7.5H18.5ZM9.5 6C9.5 4.34578 10.8458 3 12.5 3C14.1542 3 15.5 4.34578 15.5 6V7.5H9.5V6ZM17 11.625C16.3787 11.625 15.875 11.1213 15.875 10.5C15.875 9.87867 16.3787 9.375 17 9.375C17.6213 9.375 18.125 9.87867 18.125 10.5C18.125 11.1213 17.6213 11.625 17 11.625ZM8 11.625C7.37867 11.625 6.875 11.1213 6.875 10.5C6.875 9.87867 7.37867 9.375 8 9.375C8.62133 9.375 9.125 9.87867 9.125 10.5C9.125 11.1213 8.62133 11.625 8 11.625Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {};
</script>
